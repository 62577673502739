html, body {
  height: 100%;
  margin: 0px;
  padding: 0px
}
.rn-layout {
  min-height: 100vh;
}
.rn-product-card {
  position: relative;
  background-color: #fff;
}
  .rn-product-card__wishlist {
    position: absolute;
    right: 5px; top: 5px;
  }
  .rn-product-card__img {
    margin: 0 auto;
    display: block;
  }
  .rn-product-card__meta {
    padding: 20px;
    line-height: 1.1em;
  }
    .rn-product-card__title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 15px;
    }
    .rn-product-card__bottom {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
      .rn-product-card__bottom_l {
        flex: 1;
      }
        .rn-product-card__price {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
          color: red;
        }

.region {
  max-width: 1500px;
  margin: 0 auto;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff !important;
}

/** Colors **/
.text-gray {
  color: #898989 !important;
}

.img-responsive {
  max-width: 100% !important;
}
.mt-10 {margin-top: 10px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mt-20 {margin-top: 20px !important;}
.mb-20 {margin-bottom: 20px !important;}

/** tmp **/
h3, h4 {
  margin: 0 !important;
}